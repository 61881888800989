.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  position: fixed;
  bottom: 0;
  width: 100%;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.icon {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
}

.name {
  font-size: 18px;
}
